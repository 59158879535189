import { Abac } from '@gunosy/media-admin-components'
import * as Resource from '../../const/resources'
import Role from '../../const/role'
import { UserState } from '../../store/loggedInUser'
import { fmtYM_HYPHEN } from '../../utils'
import { getFirstDayOfLastMonth } from '../../utils/time'

export interface NavItem {
  icon: string
  label: string
  to?: string
  content?: NavItem[]
  resource?: Abac.Resource
  externalLink?: boolean
  hide?: (isInhouse: boolean, loggedInUser: UserState) => boolean // 権限あるけどメニューには出さない
}
export interface NavSection {
  title: string
  items: NavItem[]
}

export const Menu: NavSection[] = [
  {
    title: 'レポート',
    items: [
      {
        icon: 'pe-7s-display1',
        label: 'ホーム',
        to: '#/reports/dashboard',
        resource: Resource.SummaryStats,
      },
      {
        icon: 'pe-7s-medal',
        label: 'パブリッシャーランキング',
        to: '#/reports/publisher-ranking',
        resource: Resource.PublisherRank,
        hide: (isInhouse: boolean) => !isInhouse,
      },
      {
        icon: 'pe-7s-display2',
        label: '記事レポート',
        to: '#/reports/articles',
        resource: Resource.ArticleStats,
      },
      {
        icon: 'pe-7s-film',
        label: '動画レポート',
        to: '#/reports/video',
        resource: Resource.VideoStats,
      },
    ],
  },
  {
    title: 'パブリッシャー管理',
    items: [
      {
        icon: 'pe-7s-check',
        label: '契約管理',
        to: '#/managements/agreements',
        resource: Resource.Agreement,
        hide: (isInhouse: boolean) => !isInhouse,
      },
      {
        icon: 'pe-7s-server',
        label: 'パブリッシャー管理',
        to: '#/managements/publishers',
        resource: Resource.Publisher,
        hide: (isInhouse: boolean) => !isInhouse,
      },
      {
        icon: 'pe-7s-news-paper',
        label: 'フィード管理',
        to: '#/managements/feeds',
        resource: Resource.Feed,
      },
      {
        icon: 'pe-7s-piggy',
        label: '銀行口座管理',
        to: '#/bank-accounts',
        resource: Resource.BankAccount,
      },
    ],
  },
  {
    title: '記事管理',
    items: [
      {
        icon: 'pe-7s-note2',
        label: '記事管理',
        to: '#/managements/articles',
        resource: Resource.MediumArticle,
      },
    ],
  },
  {
    // for 社内向け
    title: '支払',
    items: [
      {
        icon: 'pe-7s-cash',
        label: '契約別支払一覧',
        to: '#/histories/payments-by-agreement',
        resource: Resource.MonthlyPayment,
        hide: (isInhouse: boolean) => !isInhouse,
      },
      {
        icon: 'pe-7s-cash',
        label: 'パブリッシャー別支払一覧',
        to: '#/histories/payments-by-publisher',
        resource: Resource.MonthlyPayment,
        hide: (isInhouse: boolean, loggedInUser: UserState) =>
          !loggedInUser.roles.includes(Role.Admin) &&
          !loggedInUser.roles.includes(Role.Insight_Admin) &&
          !loggedInUser.roles.includes(Role.Insight_Editor),
      },
    ],
  },
  {
    // for パブリッシャー向け
    title: '支払',
    items: [
      {
        icon: 'pe-7s-cash',
        label: '支払一覧',
        to: `#/histories/payments-for-publishers/month=${fmtYM_HYPHEN(getFirstDayOfLastMonth())}`,
        resource: Resource.MonthlyPayment,
        hide: (isInhouse: boolean, loggedInUser: UserState) =>
          !loggedInUser.roles.includes(Role.Insight_PublisherAdmin) &&
          !loggedInUser.roles.includes(Role.Insight_PublisherEditor),
      },
    ],
  },
  {
    title: '調整',
    items: [
      {
        icon: 'pe-7s-magic-wand',
        label: '調整金分配',
        to: '#/adjustments/dividend',
        resource: Resource.Dividend,
      },
    ],
  },
  {
    // for 社内向け
    title: 'お知らせ管理',
    items: [
      {
        icon: 'pe-7s-speaker',
        label: 'お知らせ管理',
        to: '#/announcements',
        resource: Resource.Announce,
        hide: (isInhouse: boolean) => !isInhouse,
      },
    ],
  },
  {
    // NOTE: IAMにも同様の頁があるが、'insight:publisher_editor' は agreementと紐付けないといけないのでこちらで管理する
    // NOTE: publisher側はこちらしか使わない。
    // for パブリッシャー向け
    title: 'ユーザー管理',
    items: [
      {
        icon: 'pe-7s-users',
        label: 'ユーザー管理',
        to: '#/users',
        resource: Resource.User,
        hide: (isInhouse: boolean, loggedInUser: UserState) =>
          !loggedInUser.roles.includes(Role.Insight_PublisherAdmin),
      },
    ],
  },
  {
    title: 'リンク',
    items: [
      {
        icon: 'pe-7s-link',
        label: 'Feedや収益などに関するFAQ',
        to: 'https://support.gunosy.link/hc/ja/categories/4808592419471',
        externalLink: true,
      },
      {
        icon: 'pe-7s-link',
        label: 'GunosyFeed仕様書',
        to: 'https://support.gunosy.link/hc/ja/articles/5533509025935',
        externalLink: true,
      },
      {
        icon: 'pe-7s-link',
        label: '管理画面説明書',
        to: '/gpc/guide/gunosy-publisher-center-tutorial.pdf',
        externalLink: true,
      },
      {
        icon: 'pe-7s-link',
        label: 'サービス連携規約',
        to: 'https://support.gunosy.link/hc/ja/articles/8038248964239',
        externalLink: true,
      },
      {
        icon: 'pe-7s-link',
        label: 'コンテンツ掲載ガイドライン',
        to: 'https://support.gunosy.link/hc/ja/sections/5585000600335',
        externalLink: true,
      },
    ],
  },
]
